<template>
    <main>
        <b-overlay :show="loading">
        <validation-observer ref="form">
        <!-- Info Barang -->
        <b-card>
            <h3>Info Barang</h3>
            <b-row>
                <b-col sm="12" md="8">
                    <table class="table responsive table-responsive w-100 table-borderless">
                        <tr>
                            <td><strong>Nama Barang</strong></td>
                            <td>{{barang.nama}}</td>
                            <td><strong>Kategori</strong></td>
                            <td>{{barang.kategori === 1 ? barang.kategori.kategori : 'kosong'}}</td>
                        </tr>
                        <tr>
                            <td><strong>Kode Barang</strong></td>
                            <td>{{barang.kode}}</td>
                            <td><strong>Satuan Barang</strong></td>
                            <td>{{barang.satuan ? barang.satuan.satuan : 'kosong'}}</td>
                        </tr>
                    </table>
                    
                </b-col>
            </b-row>
        </b-card>
        <!-- / -->
        <b-row>
            <!-- List Stock Barang -->
            <b-col md="6" sm="12">
                <b-card>
                    <h5>Stok Barang</h5>
                    <b-table :fields="stockFields" responsive :items="barang.stocks" bordered striped>
                        <template #cell(checkbox)="{item}">
                            <b-form-radio v-model="selectedStock" :value="item" :name="`barang`" />
                        </template>
                        <template #cell(gudang)="{item}">
                            {{ item.gudang ? item.gudang.nama_gudang : '-' }}
                        </template>
                        <template #cell(blok)="{item}">
                            {{ item.blok ? item.blok.blok : '-' }}
                        </template>
                        <template #cell(rak)="{item}">
                            {{ item.rak ? item.rak.rak : '-' }}
                        </template>
                        <template #cell(laci)="{item}">
                            {{ item.laci ? item.laci.laci : '-' }}
                        </template>
                    </b-table>
                </b-card>
            </b-col>
            <!-- / -->
            <b-col md="6" sm="12">
                <b-card>
                    <form>
                        <b-form-group>
                            <validation-provider
                                #default="{ errors }"
                                name="gudang"
                                rules="required"
                            >
                                <label for="">Pilih Gudang</label>
                                <v-select 
                                    :options="gudangs"
                                    v-model="currentStock.gudang_id"
                                    :required="!currentStock.gudang_id"
                                    :reduce="option => option.value"
                                    label="text" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group>
                            <validation-provider
                                #default="{ errors }"
                                name="blok"
                                rules="required"
                            >
                                <label for="">Pilih Blok</label>
                                <v-select 
                                    :options="bloks"
                                    v-model="currentStock.blok_id"
                                    :required="!currentStock.blok_id"
                                    :reduce="option => option.value"
                                    label="text" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group>
                            <label for="">Pilih Rak (optional)</label>
                            <v-select 
                                v-model="currentStock.rak_id"
                                :options="raks"
                                :reduce="option => option.value"
                                label="text" class="mb-2" />
                        </b-form-group>
                        <b-form-group>
                            <label for="">Pilih Laci (optional)</label>
                            <v-select 
                                v-model="currentStock.laci_id"
                                :options="lacis"
                                :reduce="option => option.value"
                                label="text" class="mb-2" />
                        </b-form-group>
                        <b-form-group >
                            <validation-provider 
                                #default="{errors}"
                                name="stok"
                                rules="required|positive"
                            >
                                <label for="">stok</label>
                                <b-form-input type="number" v-model="currentStock.stok" class="mb-2" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-form-group>
                            <b-button @click.prevent="submit" variant="primary">Simpan</b-button>
                        </b-form-group>
                    </form>
                </b-card>
            </b-col>
        </b-row>
        </validation-observer>
    </b-overlay>
    </main>
</template>
<script>
    import {BContainer, BFormRadio,BRow, BOverlay,BTable,BCard,BCol, BButton, BFormGroup, BFormInput} from 'bootstrap-vue';
    import vSelect from 'vue-select'
    import { extend } from 'vee-validate';
    import { ValidationProvider, ValidationObserver } from "vee-validate"
    import { required } from 'vee-validate/dist/rules'

    // Define rule
    extend('required', {
        ...required,
        message: 'This field is required'
    })
    extend('positive', value => {
        return value > 0
    })
    
    export default {
        components: {
            BOverlay,
            BTable,
            ValidationObserver,
            ValidationProvider,
            BCard,
            BContainer,
            BRow,
            BFormRadio,
            BCol,
            BButton,
            BFormGroup, BFormInput,
            vSelect
        },
        methods: {
            submit() {
                this.$refs.form.validate().then(success => {
                    if(success) {
                        let payload = Object.assign({}, this.currentStock)
                        if(this.id) {
                            payload.id = this.id
                        }

                        this.loading = true
                        this.$store.dispatch('penyimpanan/save', [payload])
                        .then(() => {
                            this.loading = false
                            this.$store.commit('penyimpanan/SET_DETAIL_DATA', null)
                            this.$router.push('/stock-in')
                        })
                        .catch(e => {
                            this.loading = false
                            this.displayError(e)

                            return false
                        })
                    }
                })
            },
            getGudang() {
                this.gudangs = []
                this.$store.dispatch('gudang/getData', {jenis: 1})
                .then(gudangs => {
                    gudangs.forEach(gudang => {
                        this.gudangs.push({
                            value: gudang.id,
                            text: gudang.nama_gudang
                        })
                    })
                })
            },
            getBlok(gudang_id){
                this.bloks = []
                this.$store.dispatch('blok/getData', {gudang_id})
                .then(bloks => {
                    bloks.forEach(blok => {
                        this.bloks.push({
                            value: blok.id,
                            text: blok.blok
                        })
                    })
                })
            },
            getRak(blok_id){
                this.raks = []
                this.$store.dispatch('rak/getData', {blok_id})
                .then(raks => {
                    raks.forEach(rak => {
                        this.raks.push({
                            value: rak.id,
                            text: rak.rak
                        })
                    })
                })
            },
            getLaci(rak_id){
                this.lacis = []
                this.$store.dispatch('laci/getData', {rak_id})
                .then(lacis => {
                    lacis.forEach(laci => {
                        this.lacis.push({
                            value: laci.id,
                            text: laci.laci
                        })
                    })
                })
            },
            getStock() {
                const barangWithStocks = this.$store.state.penyimpanan.detail
                this.barang = JSON.parse( JSON.stringify(barangWithStocks) )
            }
        },
        data() {
            return {
                selectedStock: null,
                currentStock: {
                    gudang_id:null,
                    blok_id:null,
                    rak_id:null,
                    laci_id:null,
                    stok: 0
                },
                id: null,
                barang: {
                    id:null,
                    stocks: []
                },
                stockFields: [
                    {key: 'checkbox', label: '#'},
                    {key: 'gudang', label: 'Gudang'},
                    {key: 'blok', label: 'Blok'},
                    {key: 'rak', label: 'Rak'},
                    {key: 'laci', label: 'Laci'},
                    {key: 'stok', label: 'stok'},
                ],
                gudangs: [],
                bloks: [],
                raks: [],
                lacis: [],
                loading: false
            }
        },
        watch: {
            selectedStock(item ){
                if(item) {
                    if(this.selectedStock.id){this.id = this.selectedStock.id}
                    if(this.selectedStock.gudang){ this.currentStock.gudang_id = this.selectedStock.gudang.id }
                    if(this.selectedStock.blok){ this.currentStock.blok_id = this.selectedStock.blok.id }
                    if(this.selectedStock.rak){ this.currentStock.rak_id = this.selectedStock.rak.id }
                    if(this.selectedStock.laci){ this.currentStock.laci_id = this.selectedStock.laci.id }
                    if(this.selectedStock.stok){ this.currentStock.stok = this.selectedStock.stok }
                }
            },
            "currentStock.gudang_id"(gudang_id) {
                this.getBlok(gudang_id)  
            },
            "currentStock.blok_id"(blok_id) {
                this.getRak(blok_id)
            },
            "currentStock.rak_id"(rak_id) {
                this.getLaci(rak_id)
            },
        },
        created() {
            if(!this.$route.params.id || !this.$store.state.penyimpanan.detail) {
                this.$router.push('/stock-in')
            }
            this.getGudang()
            this.getStock()
        }
    };
</script>